import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: object;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  description: string;
  title: string;
  file_type: string;
  document: File | string;
  file_name: string;
  fileType: string;
  model: boolean;
  doc_list: {
    id: string;
    attributes: {
      title: string;
      document_type: string | null;
      description: string;
      attachments: { file: string }[];
    };
  }[];
  form_error: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class DataStorageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadDataCallId: string = "uploadDataCallId";
  getLoggedUserDetailsCallId: string = "getLoggedUserDetailsCallId";
  deleteAFileCallId: string = "deleteAFileCallId";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      description: "",
      title: "",
      file_type: "",
      document: "",
      file_name: "",
      fileType: "",
      model: false,
      doc_list: [],
      form_error: "",

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId == this.uploadDataCallId) {
      if (responseJson) {
        this.getLoggedUserDetails();
      }
    }

    if (apiRequestCallId == this.getLoggedUserDetailsCallId) {
      if (responseJson) {
        this.setState({ doc_list: responseJson.data });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ title: event.target.value });
  };

  handleDescChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ description: event.target.value });
  };

  handleDocumentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ file_type: event.target.value });
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      this.setState({ document: selectedFile });
    }
  };

  uploadUserData = async () => {
    this.setState({ model: true });

    const header = {
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTI5MywiZXhwIjoxNjkxMTYzOTIzLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.CsgqwOxNeNGMXUsSAdmH0WIZzDBlYaa6LcizPdGry6nIV8EPpFdvalJkBafNPWSoYQJZGrK0xLFNh69vaX8ILw",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let formdata = new FormData();

    formdata.append("file_document[title]", this.state.title);
    formdata.append("file_document[description]", this.state.description);
    formdata.append("file_document[document_type]", this.state.file_type);
    formdata.append("file_document[attachments][]", this.state.document);

    this.uploadDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_datastorage/file_documents"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClose = () => {
    this.setState({ model: false });
  };

  handleDeleteDocument = async (id_str: string, index: number) => {
    let documents = this.state.doc_list;
    documents?.splice(index, 1);

    this.setState({ doc_list: documents });

    const header = {
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTI5MywiZXhwIjoxNjkxMTYzOTIzLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.CsgqwOxNeNGMXUsSAdmH0WIZzDBlYaa6LcizPdGry6nIV8EPpFdvalJkBafNPWSoYQJZGrK0xLFNh69vaX8ILw",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_datastorage/file_documents/${id_str}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getLoggedUserDetails = async () => {
    const header = {
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTI5MywiZXhwIjoxNjkxMTYzOTIzLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.CsgqwOxNeNGMXUsSAdmH0WIZzDBlYaa6LcizPdGry6nIV8EPpFdvalJkBafNPWSoYQJZGrK0xLFNh69vaX8ILw",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLoggedUserDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_datastorage/file_documents"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
